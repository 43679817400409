import Navbar from "../navbar/navbar";
import solutionsStyle from './solutions.module.css'
import icon from '../../assets/mdi_cctv.png'
import icon2 from '../../assets/uil_lock-access.png'
import icon3 from '../../assets/Vector (11).png'
import expert from '../../assets/Image (3).png'
import tick from '../../assets/Tick.png'
import contact from '../../assets/Image (4).png'
import contact8 from '../../assets/Icon (8).png'
import contact9 from '../../assets/Icon (9).png'
import contact10 from '../../assets/Icon (10).png'
import Footer from "../footer/footer";

export default function Cybersecurity (){
   return <>
<Navbar/>
<section >
  <div className={`d-flex justify-content-center align-items-center ${solutionsStyle.bgImage1}`}>
    <div className="p-5 bg-light-me rounded-5 ms-5 me-5 col-md-6">
    <span className="rounded-5 bg-success text-white py-2 px-3">Solutions</span>
      <h1 className={`pt-4 prostoOne fw-bold`}>Delivering transformative solutions.</h1>
    </div>
  </div>
</section>
<section className='container my-5'>

   <div className="w-75">
      <h2>Cybersecurity solutions</h2>
      <p>Cybersecurity solutions are designed to protect an organization’s digital assets, networks, and data from cyber threats. Here are the key components of a comprehensive cybersecurity solution offered by a security company:</p>
   </div>
   <div className="pt-4 row justify-content-between">
      <div className="col-md-3">
         <div className="rounded-5 bg-light p-4 m-3">
         <img src={icon} alt="" />
         <h3 className="pt-4">Network Security</h3>
         <p className="pt-3">
         Implements measures to protect the integrity, confidentiality, and availability of network and data. This includes firewalls, intrusion detection systems (IDS), intrusion prevention systems (IPS), and secure network architecture.         </p>
      </div>
         </div>
         <div className="col-md-3">
         <div className="rounded-5 bg-light p-4 m-3">
         <img src={icon2} alt="" />
         <h3 className="pt-4">Endpoint Security</h3>
         <p className="pt-3">
         Protects devices such as computers, smartphones, and tablets from malicious attacks. Solutions include antivirus software, anti-malware, and endpoint detection and response (EDR) tools.          </p>
  
         </div>
       
   </div>
   <div className="col-md-3">
         <div className="rounded-5 bg-light p-4 m-3">
         <img src={icon3} alt="" />
         <h3 className="pt-4">Data Security</h3>
         <p className="pt-3">
         Ensures the protection of data at rest and in transit. This includes encryption, secure data storage solutions, data loss prevention (DLP), and access control mechanisms.          </p>
      </div>
         </div>
         <div className="col-md-3">
         <div className="rounded-5 bg-light p-4 m-3">
         <img src={icon3} alt="" />
         <h3 className="pt-4">Application Security</h3>
         <p className="pt-3">
         Involves securing web applications and services from threats and vulnerabilities. This includes secure coding practices, web application firewalls (WAF), and application vulnerability scanning.
</p>
      </div>
         </div>
   </div>
</section>
<section className="container py-5 mt-5">
        <div className="row">
        <div className="col-md-6">
            <img src={expert} className="w-100" alt="" />
        </div>
          <div className="col-md-6 text-start pt-3">
            <div className="px-5">
              <span className="rounded-5 bg-success text-white py-2 px-3">WE'LL WORK WITH YOU</span>
     <div className="">
<h2 className="h1 pt-4">What To Expect</h2>
<p>When you partner with Nets Integrated Solutions, you can expect a seamless and collaborative experience designed to meet your specific needs and goals. Here's what you can look forward to</p>
    <div className="d-flex">
      <div className=" pe-3">
<img src={tick} alt="" />
      </div>
<div>
We start by understanding your business objectives, current IT landscape, and unique challenges, allowing us to tailor our solutions to align perfectly with your requirements.
</div>
    </div>
    <div className="d-flex pt-4">
      <div className=" pe-3">
<img src={tick} alt="" />
      </div>
<div>
We start by understanding your business objectives, current IT landscape, and unique challenges, allowing us to tailor our solutions to align perfectly with your requirements.
</div>
    </div>
     </div>
      <div>

      </div>
            </div>
          </div>

        </div>
      </section>
      <section className="row justify-content-center align-items-center m-auto container pt-5 ">
         <div className="col-lg-6 p-5">
        <div className="p-3">
<h2 className="h1">Contact Us. It’s Easy.</h2>
<p>Reach out to us today for tailored IT solutions that drive your business forward. Contact us now to discuss your needs!"</p>
<div className="d-flex pt-4">
      <div className=" pe-3">
<img src={contact8} alt="" />
      </div>
<div className="">
<h6 className="text-muted">Call Today</h6>
<p className="fw-bold">+20222703426 - 
01029059916</p>
</div>
    </div>
    <div className="d-flex pt-4">
      <div className=" pe-3">
<img src={contact9} alt="" />
      </div>
<div className="">
<h6 className="text-muted">Sunday To Thursday</h6>
<p className="fw-bold">Response hours: 9:00 AM - 5:00 PM</p>
</div>
    </div>
    <div className="d-flex pt-4">
      <div className=" pe-3">
<img src={contact10} alt="" />
      </div>
<div className="">
<h6 className="text-muted">Cairo Office</h6>
<p className="fw-bold">4 Esmat Mansour, 8th area,
Nasr City, Cairo, Egypt


</p>
</div>
    </div>
        </div>
         </div>
         <div className="col-lg-6">
<img src={contact} className="w-100" alt="" />
         </div>
      </section>
      <section className="container d-flex justify-content-center my-5 bg-light rounded-5">
      <div className="col-md-8 py-5">
         <div className="text-center pt-5">
         <span className="rounded-5 bg-success text-white py-2 px-3">FAQ</span>
         <h2 className="pt-4">Customer Questions</h2>
         </div>
              <div className="mb-4 border-bottom">
                  <div
                    className="bg-light py-3 px-4 d-flex justify-content-between align-items-center "
                    data-bs-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <div>
                      <h5 className="arabic-font">What services do you offer?</h5>
                    </div>
                    <div>
                      <i className="fa-solid fa-angle-down "></i>
                    </div>
                  </div>
                <div className="collapse bg-light" id="collapseExample">
                  <div className="card card-body bg-light border-0  rounded-0 mx-3">
                    <div className="row">
                    We prioritize data security by providing comprehensive cybersecurity solutions. This includes risk assessments, vulnerability management, proactive monitoring, and implementing best practices to protect your business from potential threats
                    </div>
                  </div>
                </div>
              </div>
               <div className="mb-4 border-bottom">
                  <div
                    className="bg-light py-3 px-4 d-flex justify-content-between align-items-center "
                    data-bs-toggle="collapse"
                    href="#collapseExample1"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample1"
                  >
                    <div>
                      <h5 className="arabic-font">How can you ensure the security of our data?</h5>
                    </div>
                    <div>
                      <i className="fa-solid fa-angle-down "></i>
                    </div>
                  </div>
                <div className="collapse bg-light" id="collapseExample1">
                  <div className="card card-body bg-light border-0  rounded-0 mx-3">
                    <div className="row">
                    We prioritize data security by providing comprehensive cybersecurity solutions. This includes risk assessments, vulnerability management, proactive monitoring, and implementing best practices to protect your business from potential threats
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 border-bottom">
                  <div
                    className="bg-light py-3 px-4 d-flex justify-content-between align-items-center "
                    data-bs-toggle="collapse"
                    href="#collapseExample2"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample2"
                  >
                    <div>
                      <h5 className="">What makes your services different from other IT service providers?</h5>
                    </div>
                    <div>
                      <i className="fa-solid fa-angle-down "></i>
                    </div>
                  </div>
                <div className="collapse bg-light" id="collapseExample2">
                  <div className="card card-body bg-light border-0  rounded-0 mx-3">
                    <div className="row">
                    We prioritize data security by providing comprehensive cybersecurity solutions. This includes risk assessments, vulnerability management, proactive monitoring, and implementing best practices to protect your business from potential threats
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 border-bottom">
                  <div
                    className="bg-light py-3 px-4 d-flex justify-content-between align-items-center "
                    data-bs-toggle="collapse"
                    href="#collapseExample3"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample3"
                  >
                    <div>
                      <h5 className="arabic-font">How do you ensure your solutions are up-to-date with the latest technology?</h5>
                    </div>
                    <div>
                      <i className="fa-solid fa-angle-down "></i>
                    </div>
                  </div>
                <div className="collapse bg-light" id="collapseExample3">
                  <div className="card card-body bg-light border-0  rounded-0 mx-3">
                    <div className="row">
                    We prioritize data security by providing comprehensive cybersecurity solutions. This includes risk assessments, vulnerability management, proactive monitoring, and implementing best practices to protect your business from potential threats
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 border-bottom">
                  <div
                    className="bg-light py-3 px-4 d-flex justify-content-between align-items-center "
                    data-bs-toggle="collapse"
                    href="#collapseExample4"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample4"
                  >
                    <div>
                      <h5 className="arabic-font">Do you offer support after the project is completed?</h5>
                    </div>
                    <div>
                      <i className="fa-solid fa-angle-down "></i>
                    </div>
                  </div>
                <div className="collapse bg-light" id="collapseExample4">
                  <div className="card card-body bg-light border-0  rounded-0 mx-3">
                    <div className="row">
                    We prioritize data security by providing comprehensive cybersecurity solutions. This includes risk assessments, vulnerability management, proactive monitoring, and implementing best practices to protect your business from potential threats
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 border-bottom">
                  <div
                    className="bg-light py-3 px-4 d-flex justify-content-between align-items-center "
                    data-bs-toggle="collapse"
                    href="#collapseExample5"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample5"
                  >
                    <div>
                      <h5 className="arabic-font"> Do you provide customized solutions?</h5>
                    </div>
                    <div>
                      <i className="fa-solid fa-angle-down "></i>
                    </div>
                  </div>
                <div className="collapse bg-light" id="collapseExample5">
                  <div className="card card-body bg-light border-0  rounded-0 mx-3">
                    <div className="row">
                    We prioritize data security by providing comprehensive cybersecurity solutions. This includes risk assessments, vulnerability management, proactive monitoring, and implementing best practices to protect your business from potential threats
                    </div>
                  </div>
                </div>
              </div>
            </div>  
      </section>
      <Footer/>

   </>
}