import Navbar from "../navbar/navbar";
import contactStyle from './contatc.module.css'
import icon12 from '../../assets/Icon (12).png'
import icon11 from '../../assets/Icon (11).png'
import icon13 from '../../assets/Icon (13).png'
import contact8 from '../../assets/Icon (8).png'
import contact9 from '../../assets/Icon (9).png'
import contact10 from '../../assets/Icon (10).png'
import contact from '../../assets/Image (8).png'
import Footer from "../footer/footer";
import ContactForm from "./contact-form";

export default function Contact (){
   return <>
<Navbar/>
<section >
  <div className={`d-flex justify-content-center align-items-center ${contactStyle.bgImage1}`}>
    <div className="p-5 bg-light-me rounded-5 ms-5 me-5 col-md-6">
    <span className="rounded-5 bg-success text-white py-2 px-3">CONTACT US</span>
      <h1 className={`pt-4 prostoOne fw-bold`}>Feel Free To Reach Us</h1>
    </div>
  </div>
</section>
<section className="container py-5 mt-5">
        <div className="row">
        <div className="col-md-6">
            <img src={contact} className="w-100" alt="" />
        </div>
          <div className="col-md-6 text-start pt-3">
            <div className="px-5">
              <span className="rounded-5 bg-success text-white py-2 px-3">Let’s talk</span>
     <div className="">
<h2 className="h1 pt-4">Get In Touch With Us</h2>
<p>Have questions or need assistance? Our team is here to help. Contact us to get started on your IT journey!</p>
   <div className="d-flex pt-4">
      <div className=" pe-3">
<img src={icon11} alt="" />
      </div>
      <div>
<h6>
Have Any Question?
</h6>
<p>+20222703426 - 
01029059916</p>
      </div>
    </div>
    <div className="d-flex pt-4">
      <div className=" pe-3">
<img src={icon12} alt="" />
      </div>
      <div>
<h6>
Write Email
</h6>
<p>info@nets-is.com</p>

      </div>
    </div>
    <div className="d-flex pt-4">
      <div className=" pe-3">
<img src={icon13} alt="" />
      </div>
      <div>
<h6>
Visit Anytime
</h6>
<p>4 Esmat Mansour, 8th area,
Nasr City, Cairo, Egypt

</p>
      </div>
    </div>
     </div>
      <div>

      </div>
            </div>
          </div>

        </div>
      </section>
      <section className={`row m-auto justify-content-center align-items-center my-5 ${contactStyle.contact} ${contactStyle.bgImage}`}>
      <div className="col-12 col-sm-6 col-lg-3 col-md-4">
         <h3 className="text-white">Subscribe Our Newsletter</h3>
         <p className="text-white">Build stronger Customer Relationships with nets</p>
      </div>
      <div className="col-12 col-sm-6 col-lg-3 col-md-4">
      <div className="mb-3 d-flex justify-content-between">
  <input type="email" className="form-control rounded-5 w-75 me-5" id="exampleFormControlInput1" placeholder="your mail address"/>
<button className="btn btn-success rounded-5">Subscribe</button>
</div>
      </div>
      </section>
      {/* <section className="container">
      <div className="row justify-content-center align-items-center">
         <div className="col-lg-6 p-5">
        <div className="p-3">
<h2 className="h1">Contact Us. It’s Easy.</h2>
<p>Reach out to us today for tailored IT solutions that drive your business forward. Contact us now to discuss your needs!"</p>
<div className="d-flex pt-4">
      <div className=" pe-3">
<img src={contact8} alt="" />
      </div>
<div className="">
<h6 className="text-muted">Call Today</h6>
<p className="fw-bold">+20222703426 - 
01029059916</p>
</div>
    </div>
    <div className="d-flex pt-4">
      <div className=" pe-3">
<img src={contact9} alt="" />
      </div>
<div className="">
<h6 className="text-muted">Sunday To Thursday</h6>
<p className="fw-bold">Response hours: 9:00 AM - 5:00 PM</p>
</div>
    </div>
    <div className="d-flex pt-4">
      <div className=" pe-3">
<img src={contact10} alt="" />
      </div>
<div className="">
<h6 className="text-muted">Cairo Office</h6>
<p className="fw-bold">4 Esmat Mansour, 8th area,
Nasr City, Cairo, Egypt


</p>
</div>
    </div>
        </div>
         </div>
         <div className="col-lg-6 px-5">
            <div className="row justify-content-around">
         <div className="mb-3 col-12 col-md-5 ">
  <label htmlFor="exampleFormControlInput1" className="form-label">Full Name</label>
  <input type="text" className="form-control rounded-5 py-2" id="exampleFormControlInput1" />
</div>
<div className="mb-3 col-12 col-md-5">
  <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
  <input type="email" className="form-control rounded-5 pe-4 py-2" id="exampleFormControlInput1" />
</div>
<div className="mb-3 col-12 col-md-5">
  <label htmlFor="exampleFormControlInput1" className="form-label">Phone</label>
  <input type="email" className="form-control rounded-5 pe-4 py-2" id="exampleFormControlInput1" />
</div>
<div className="mb-3 col-12 col-md-5">
  <label htmlFor="exampleFormControlInput1" className="form-label">Company(optional)</label>
  <input type="email" className="form-control rounded-5 pe-4 py-2" id="exampleFormControlInput1" />
</div>
            </div>
<div className="mb-3 px-4 col-12 ">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">Message</label>
  <textarea className="form-control rounded-4" id="exampleFormControlTextarea1" rows="4" placeholder="Briefly tell us about your project and your current goals. How can we help you?"></textarea>
</div>
<button className="btn btn-dark rounded-5 ms-4 mb-5">Send Message</button>
         </div>
      </div>
      </section> */}
     <ContactForm/>
      <Footer/>
   </>
}