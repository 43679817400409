import Navbar from "../navbar/navbar";
import aboutStyle from './about.module.css'
import about from '../../assets/Image (9).png'
import mission from '../../assets/Image (10).png'
import Experience from '../../assets/Content.png'

import Footer from "../footer/footer";


export default function About (){
   return <>
<Navbar/>
<section >
  <div className={`d-flex justify-content-center align-items-center ${aboutStyle.bgImage}`}>
    <div className="p-5 bg-light-me rounded-5 ms-5 me-5 col-md-6">
    <span className="rounded-5 bg-success text-white py-2 px-3">ABOUT US</span>
      <h1 className={`pt-4 prostoOne fw-bold`}>Bring your visions to life.</h1>
    </div>
  </div>
</section>
<section className="container py-5 mt-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-6 text-start pt-3">
            <div className="px-5">
              <span className="rounded-5 bg-success text-white py-2 px-3">About Us</span>
     <div className="">
<h2 className="h1 pt-4">Turn your ideas into reality.</h2>
<p>
Welcome to Nets Integrated Solutions, your trusted partner in IT infrastructure excellence. Founded with a vision to revolutionize the digital landscape, we specialize in delivering comprehensive IT solutions that empower businesses to thrive in an ever-evolving technological world.Who We AreAt Nets Integrated Solutions, we are a team of dedicated IT professionals with a passion for innovation and a commitment to excellence. Our diverse team brings together years of expertise in networking, cloud computing, cybersecurity, hardware solutions
</p>

     </div>
      <div>

      </div>
            </div>
          </div>
          <div className="col-md-6">
            <img src={about} className="w-100" alt="" />
        </div>
        </div>
      </section>
      <section className="container d-flex justify-content-center align-items-center">
         <div className="py-5 row justify-content-center align-items-center col-12">
              <div className="col-6 col-md-3 d-flex align-items-center justify-content-center">
                <div>
                  <h3 className="pb-0 mb-0 fw-bold">50+</h3>
                  <p className="text-muted">Successful Work</p>
                </div>
              </div>
              <div className="col-6 col-md-3 d-flex align-items-center justify-content-center">
                <div>
                  <h3 className="pb-0 mb-0 fw-bold">100+</h3>
                  <p className="text-muted">Happy Customers</p>
                </div>
              </div>
              <div className="col-6 col-md-3 d-flex align-items-center justify-content-center">
                <div>
                  <h3 className="pb-0 mb-0 fw-bold">50+</h3>
                  <p className="text-muted">Creative Idea</p>
                </div>
              </div>
         </div>
      </section>
      <section className={`d-flex justify-content-center align-items-center p-5 my-5 ${aboutStyle.bgImage1}`}>

<div className="col-md-6 px-5">
<span className="rounded-5 bg-white py-2 px-3">Experience</span>
   <h2 className="h1 pt-3 ">
   More than 25 years of experience
   </h2>
   <p>3000+ our clients are insured around the World</p>
</div>
<div className="col-md-6 px-5">
<img src={Experience} className="w-100" alt="" />
</div>

      </section>
      <section className="container py-5 mt-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-6 text-start pt-3">
            <div className="px-5">
              <span className="rounded-5 bg-success text-white py-2 px-3">Our Mission</span>
     <div className="">
<h2 className="h1 pt-4">Why Choose Us</h2>
<p>
Our mission is to help businesses achieve their full potential through cutting-edge, scalable IT infrastructure solutions. We are committed to delivering innovative and reliable services that not only meet the current needs of our clients but also anticipate and adapt to future demands. By focusing on quality, efficiency, and customer satisfaction, we aim to build long-lasting partnerships and drive success across various industries.
We believe in a proactive approach, continually enhancing our offerings to stay ahead of technological advancements and industry trends. Our goal is to empower businesses with the tools and expertise they need to thrive in a competitive and ever-evolving digital landscape. Through our dedication to excellence and continuous improvement, we strive to be a trusted partner in your journey towards technological growth and business success.
</p>
     </div>
      <div>

      </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <img src={mission} className="w-75" alt="" />
        </div>
        </div>
      </section>
      <Footer/>
   </>
}