import React from 'react'
import netsLogo from '../../assets/nets logo.png'
import { Link } from 'react-router-dom'
export default function Navbar() {
  return <>
<nav className="navbar navbar-expand-lg position-fixed w-100 w-90 z-index my-0">
  <div className="container margin-small navbar-light bg-light border border-success rounded-5 px-5 mt-4">
    <div className='navBar'>
      <Link to="/">
    <img className='logo ' src={netsLogo} alt="regency logo"/>
      </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" 
    data-bs-target="#navbarNavDropdown" 
     aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    </div>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav ms-auto">
        <li className="nav-item pe-3">
          <Link className="nav-link active" to='/'>Home</Link>
        </li>
        <li className="nav-item pe-3">
          <Link className="nav-link" to="/about-us">About us</Link>
        </li>
        <li className="nav-item pe-3">
          <Link className="nav-link" to="/solutions">Solutions</Link>
        </li>
        <li className="nav-item pe-3">
          <Link className="nav-link" to="/partners">Partners</Link>
        </li>
        <li className="nav-item pe-3">
          <Link className="nav-link" to="/customers">Our Customers</Link>
        </li>
        <li className="nav-item pe-3">
          <Link className="nav-link" to="/contact-us">Contact Us</Link>
        </li>
      </ul>
  
  </div>
  </div>
</nav>
    </>

}
