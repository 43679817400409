import Navbar from "../navbar/navbar";
import customerStyle from './customer.module.css'
import logos from '../../assets/Client Logo (6).png'
import logos2 from '../../assets/Client Logo (7).png'
import Footer from "../footer/footer";

export default function Customer (){
   return <>
<Navbar/>
<section >
  <div className={`d-flex justify-content-center align-items-center ${customerStyle.bgImage}`}>
    <div className="p-5 bg-light-me rounded-5 ms-5 me-5 col-md-6">
    <span className="rounded-5 bg-success text-white py-2 px-3">customers</span>
      <h1 className={`pt-4 prostoOne fw-bold`}>Empowering customers through technology.</h1>
    </div>
  </div>
</section>
<section className='container my-5 text-center'>

   <div className="w-75 m-auto pb-5 mb-5">
      <h2>Trusted by more than 100 users globally</h2>
      <p>Unique and powerful suite of software to run your entire business, brought to you  transform the way you work.</p>
   </div>
<div className="container m-auto">
   <img src={logos} className="w-100" alt="customers logo" />
   <img src={logos2} className="w-100 mt-5" alt="customers logo" />

</div>
</section>

      <Footer/>

   </>
}