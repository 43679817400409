import Navbar from '../navbar/navbar';

import homeStyle from './home.module.css'
import badge from '../../assets/Group (2).png'
import customer from '../../assets/Group 16.png'
import customer3 from '../../assets/Icon (5).png'
import customer4 from '../../assets/Icon (6).png'
import image4 from '../../assets/5889649-removebg-preview.png'
import aboutUs from '../../assets/Image (1).png'
import trustedImage from '../../assets/Frame 1597883389.png'
import star from '../../assets/Star 12.png'
import smile from '../../assets/Vector (1).png'
import solution1 from '../../assets/Photo.png'
import solution2 from '../../assets/Photo (3).png'
import solution3 from '../../assets/Photo (2).png'
import contact8 from '../../assets/Icon (8).png'
import contact9 from '../../assets/Icon (9).png'
import contact10 from '../../assets/Icon (10).png'
import services1 from '../../assets/Icon.png'
import services2 from '../../assets/Icon (1).png'
import services3 from '../../assets/Icon (2).png'
import services4 from '../../assets/Icon (3).png'
import vector1 from '../../assets/Vector (2).png'
import vector2 from '../../assets/Vector (3).png'
import vector3 from '../../assets/Vector (4).png'
import vector4 from '../../assets/Vector (5).png'
import clientLogo from '../../assets/Client Logo (6).png'
import tec from '../../assets/Frame 1000000844 (1).png'
import reviews from '../../assets/Group (3).png'
import icon1 from '../../assets/Icon (4).png'
import { Link } from 'react-router-dom';
import Footer from '../footer/footer';
import PartnersSlider from '../partners/partnersSlider';
import CustomersSlider from '../customers/customersSlider';
import ContactForm from '../contact/contact-form';
export default function Home(){
return <>
<Navbar/>

<section className={`${homeStyle.positionRelative}`}>
  <div className={`vh-50 d-flex justify-content-start align-items-center ${homeStyle.bgImage}`}>
   <div className='bannerBgColor d-flex'>
    <div className="p-5 margin marginSmall text-center-me">
      <h2 className={`fs-2  text-whiteMe`}>We Believe that</h2>
      <div className='d-flex justify-content-start align-items-center pb-2 '>
      <img src={tec} className='w-60' alt="" />
      <h2 className="pt-3 ps-3 fs-1  text-whiteMe">can</h2>
      </div>
      <h1 className=" text-whiteMe">Change the World</h1>
      <div className='d-flex justify-content-start align-items-center'>
      <button className="btn btn-success mt-4 rounded-5 px-4 py-2 me-4 fs-5">Get Started</button>
        <img src={reviews} className='pt-4 ' alt="" />
      </div>
      <div>
        <div className="d-flex pt-5">
          <div>
            <img src={badge} className="w-100" alt="" />
          </div>
          <div className="ps-4 pt-1 text-whiteMe">
            <h4 className="pb-0 mb-0 fw-bold ">50+</h4>
            <p >Successful projects</p>
          </div>
          <div className="ps-5 pt-1 text-whiteMe">
            <h4 className="pb-0 mb-0 fw-bold">100+</h4>
            <p>Satisfied Clients</p>
          </div>
        </div>
      </div>
    </div>
   </div>
  </div>
  <div className={`d-flex justify-content-center align-items-start pt-5 ${homeStyle.bgImage2} ${homeStyle.positionAbsolute}`}>
    <div className="container text-center pt-5">
      <h6 className="text-uppercase">Trusted by Leading Companies</h6>
      <div className="pt-5 slider-me">
        <PartnersSlider/>
    </div>
      </div>

  </div>
</section>

<section className='pt-5 mt-5'>
<div className="container pt-5">
        <div className="row justify-content-center pt-5">
          <div className="col-12 col-lg-4 mb-5">
            <img src={trustedImage} className="w-100" alt="" />
          </div>
          <div className="col-12 col-lg-7 d-flex justify-content-center align-items-center bg-light rounded-5 ms-2 p-5 mb-5">
            <div className="row text-center">
              <div className=" col-12 col-sm-3 my-4 text-center">
                <h2 className="fw-smiBold">Trusted Partner</h2>
              </div>
              <div className="col-12 col-sm-3 d-flex align-items-center my-4">
                <div className='w-100 text-center'>
                  <h3 className="pb-0 mb-0 fw-bold text-success">50+</h3>
                  <p className="text-muted">Complete Project</p>
                </div>
              </div>
              <div className="col-12 col-sm-3 d-flex align-items-center my-4">
                <div className='w-100 text-center'>
                  <h3 className="pb-0 mb-0 fw-bold text-success">100+</h3>
                  <p className="text-muted">Client Trusted</p>
                </div>
              </div>
              <div className=" col-12 col-sm-3 d-flex align-items-center justify-content-center">
                <button className="btn btn-success rounded-5">Let's work</button>
              </div>
            </div>
          </div>
        </div>
      </div>
</section>
<div className="container text-center py-5">
      <h6 className="text-uppercase">Trusted by more than 100 users globally</h6>
      <div className="pt-5  slider-me">
        <CustomersSlider/>
    </div>
      </div>
<section className="container pb-5 mt-5">
        <div className="row">
          <div className="col-12 col-lg-6 text-start mb-5">
            <div className="px-5">
              <span className="rounded-5 bg-success text-white py-2 px-3">ABOUT US</span>
              <h2 className="mt-4 mb-5">One of the Fastest Way to Business Growth</h2>
              <p className="text-muted pb-5">Welcome to Nets Integrated Solutions, your trusted partner in IT infrastructure excellence. Founded with a vision to revolutionize the digital landscape, we specialize in delivering comprehensive IT solutions that empower businesses to thrive in an ever-evolving technological world. Who We Are At Nets Integrated Solutions <Link className='text-decoration-none text-black' to='/solutions'>, ..Read more</Link> </p>
              <div className="d-flex justify-content-between py-3 border-top border-bottom">
                <div className="border-end col-6">
                  <div className="py-2 bg-light me-3 rounded-4 px-3">
                    <div className="d-flex">
                      <div className="col-2 mx-2">
                        <img src={star} className="w-100" alt="" />
                      </div>
                      <h4 className="fw-bold">50+</h4>
                    </div>
                    <div>
                      <p className="fw-semibold">Business Services</p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="p-2 ms-3 bg-light rounded-4 px-3">
                    <div className="d-flex">
                      <div className="col-2 mx-2">
                        <img src={smile} className="w-100" alt="" />
                      </div>
                      <h4 className="fw-bold">100+</h4>
                    </div>
                    <div>
                      <p className="fw-semibold">Happy Customer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-5">
            <img src={aboutUs} className="w-100" alt="" />
          </div>
        </div>
      </section>
<section className='container my-5'>
   <div className='d-flex justify-content-center'>
      <div className='col-12  col-lg-6 text-center mb-3'>
      <h2>Our Solutions</h2>
      <p className='text-muted'>Nets Integrated Solutions crafted a comprehensive plan to address these challenges, leveraging our expertise in IT infrastructure and light current solutions. The project was executed in several phases:
      </p>
      </div>
   </div>
   <div className='row justify-content-center align-items-center '>
<div className='col-lg-3   col-sm-6 col-md-4 mb-5'>
   <div className='item shadow rounded-5 px-5 pb-5'>
<img src={solution1} className='my-2 w-100'  alt="" />
<h4 className='pb-3'>IT Infrastructure Overhaul</h4>
<p>Assessment and Planning: Conducted a thorough assessment of the existing infrastructure and identified critical .....
</p>
<Link to='/iT-infrastructure' className='btn btn-success rounded-5 px-3 py-2'>See more</Link>
   </div>
</div>
<div className='col-lg-3   col-sm-6 col-md-4 mb-5'>
   <div className='item shadow rounded-5 px-5 pb-5'>
<img src={solution2} className='my-4 w-100' alt="" />
<h4 className='pb-3'>Advanced Light Current Solutions</h4>
<p>CCTV and Surveillance Systems: Installed high-definition CCTV cameras with  ..</p>
<Link to='/advanced-light' className='btn btn-success rounded-5 px-3 py-2'>See more</Link>
   </div>
</div>
<div className='col-lg-3   col-sm-6 col-md-4 mb-5'>
   <div className='item shadow rounded-5 px-5 pb-5'>
<img src={solution3} className='my-2 w-100' alt="" />
<h4 className='pb-3'>Digital Transformation and Energy Efficiency</h4>
<p>Smart Building Solutions:* Introduced smart building technologies, .....
</p>
<Link to='/digital-transformation' className='btn btn-success rounded-5 px-3 py-2'>See more</Link>
   </div>
</div>
<div className='col-lg-3   col-sm-6 col-md-4 mb-5'>
   <div className='item shadow rounded-5 px-5 pb-5 text-center'>
    <div>
<img src={image4} className='my-2 w-75' alt="" />
    </div>
<h4 className='pb-3'>Cybersecurity solutions</h4>
<p>Cybersecurity solutions are designed to protect an organization’s digital assets.....
</p>
<Link to='/cybersecurity' className='btn btn-success rounded-5 px-3 py-2'>See more</Link>
   </div>
</div>
      </div>
</section>
<section className='py-5 bg-light'>
   <div className='container'>
   <div>
      <span className="rounded-5 bg-success text-white py-2 px-3">ABOUT US</span>
      <div className='row justify-content-between align-items-center'>
         <div className='col-md-6'>
      <h2 className=" mt-4 mb-5 h1">The client faced several challenges:</h2>
         </div>
      <div className='col-md-2 paddingBottom-me'>
      <button className='btn btn-dark rounded-5 px-3 py-2'>Get in touch</button>
      </div>

      </div>
   </div>
   <div className='row'>
<div className='col-sm-6 col-md-4 col-lg-3 mb-5'>
   <div className='item bg-white px-4 py-5 rounded-5'>
<img src={services1} alt="" />
<h4 className='py-2'>Outdated Infrastructure</h4>
<p className='pb-4'> The existing IT infrastructure was outdated, leading to frequent downtimes and inefficiencies.</p>
<Link className='text-dark text-decoration-none '>
<span className='pe-2'>Read More</span>
<i className="fa-solid fa-arrow-right"></i>
</Link>
   </div>
</div>
<div className='col-sm-6 col-md-4 col-lg-3 mb-5'>
   <div className='item bg-white px-4 py-5 rounded-5'>
<img src={services2} alt="" />
<h3 className='py-2'>Security Concerns</h3>
<p className='pb-4'>The current security systems, including CCTV and access control, were insufficient to handle the growing foot traffic and security needs.</p>
<Link className='text-dark text-decoration-none '>
<span className='pe-2'>Read More</span>
<i className="fa-solid fa-arrow-right"></i>
</Link>
   </div>
</div>
<div className='col-sm-6 col-md-4 col-lg-3 mb-5'>
   <div className='item bg-white px-4 py-5 rounded-5'>
<img src={services3} alt="" />
<h3 className='py-2'>Scalability Issues</h3>
<p className='pb-4'> The client needed a scalable solution that could grow with their expanding portfolio.</p>
<Link className='text-dark text-decoration-none '>
<span className='pe-2'>Read More</span>
<i className="fa-solid fa-arrow-right"></i>
</Link>
   </div>
</div>
<div className='col-sm-6 col-md-4 col-lg-3 mb-5'>
   <div className='item bg-white px-4 py-5 rounded-5'>
<img src={services4} alt="" />
<h3 className='py-2'>Energy Efficiency</h3>
<p className='pb-4'>The client aimed to reduce energy consumption and operational costs through smarter IT and lighting solutions.</p>
<Link className='text-dark text-decoration-none ' >
<span className='pe-2'>Read More</span>
<i className="fa-solid fa-arrow-right"></i>
</Link>
   </div>
</div>
   </div>
   </div>
</section>
<section className='container py-5'>
   <div className='row'>
<div className='col-sm-6 col-md-4 col-lg-3 mb-5 p-5 text-center'>
   <img src={vector1}  alt="" />
   <h2 className='pt-5'>expertise</h2>
   <p>Our team of experienced experts have the knowledge and expertise
to deliver innovative IT solutions
that meet your unique needs.</p>
</div>
<div className='col-sm-6 col-md-4 col-lg-3 mb-5 p-5 text-center'>
   <img src={vector2} alt="" />
   <h2 className='pt-5'>technology</h2>
   <p>We stay up to date with the latest trends and technologies in the IT industry, so you can get the most advanced solutions available.   </p>
</div>
<div className='col-sm-6 col-md-4 col-lg-3 mb-5 p-5 text-center'>
   <img src={vector3} alt="" />
   <h2 className='pt-5'>solutions</h2>
   <p>We take a personalized approach
   to every project, working closely with you to understand your business and create solutions.</p>
</div>
<div className='col-sm-6 col-md-4 col-lg-3 mb-5 p-5 text-center'>
   <img src={vector4} alt="" />
   <h2 className='pt-5'>results</h2>
   <p>Our track record speaks for itself – we've helped businesses of all sizes and industries achieve their goals with our IT solutions.</p>
</div>
   </div>
</section>
<section className='container py-5'>
<div className='d-flex justify-content-center '>
      <div className='col-6 text-center'>
      <h2>Trusted by more than 100 users globally</h2>
      <p className='text-muted'>Unique and powerful suite of software to run your entire business, brought to you  transform the way you work.</p>
      </div>
   </div>
   <div className='d-flex justify-content-center pt-5'>
      <img src={clientLogo} className='w-100' alt="" />
   </div>
</section>
<section className='container py-5'>
<div className='row'>
   <div className="col-sm-6 col-md-4 ">
      <div className='item border-end my-5 px-4 '>
   <div className="d-flex justify-content-center">
          <div>
            <img src={icon1} className="w-100 pt-2" alt="" />
          </div>
          <div className="ps-3 ">
            <h2 className="pb-0 mb-0 fw-bold h1">100+</h2>
            <p>Satisfied Clients</p>
          </div>
        </div>

      </div>
   </div>
   <div className="col-sm-6 col-md-4">
      <div className='item border-end my-5 px-4'>
   <div className="d-flex justify-content-center">
          <div>
            <img src={customer4} className="w-100 pt-2" alt="" />
          </div>
          <div className="ps-3 ">
            <h2 className="pb-0 mb-0 fw-bold h1">50+</h2>
            <p>Activate Products</p>
          </div>
        </div>

      </div>
   </div>
   <div className="col-sm-6 col-md-4">
      <div className='item my-5 px-4'>
   <div className="d-flex justify-content-center">
          <div>
            <img src={customer} className="w-100 pt-2" alt="" />
          </div>
          <div className="ps-3 ">
            <h2 className="pb-0 mb-0 fw-bold h1">18+</h2>
            <p>Awards Winning</p>
          </div>
        </div>

      </div>
   </div> 
</div>
</section>
{/* <section className="container">
      <div className="row justify-content-center align-items-center">
         <div className="col-lg-6 p-5">
        <div className="p-3">
<h2 className="h1">Contact Us. It’s Easy.</h2>
<p>Reach out to us today for tailored IT solutions that drive your business forward. Contact us now to discuss your needs!"</p>
<div className="d-flex pt-4">
      <div className=" pe-3">
<img src={contact8} alt="" />
      </div>
<div className="">
<h6 className="text-muted">Call Today</h6>
<p className="fw-bold">+20222703426 - 
01029059916</p>
</div>
    </div>
    <div className="d-flex pt-4">
      <div className=" pe-3">
<img src={contact9} alt="" />
      </div>
<div className="">
<h6 className="text-muted">Sunday To Thursday</h6>
<p className="fw-bold">Response hours: 9:00 AM - 5:00 PM</p>
</div>
    </div>
    <div className="d-flex pt-4">
      <div className=" pe-3">
<img src={contact10} alt="" />
      </div>
<div className="">
<h6 className="text-muted">Cairo Office</h6>
<p className="fw-bold">4 Esmat Mansour, 8th area,
Nasr City, Cairo, Egypt


</p>
</div>
    </div>
        </div>
         </div>
         <div className="col-lg-6 px-5">
            <div className="row justify-content-around">
         <div className="mb-3 col-12 col-md-5 ">
  <label htmlFor="exampleFormControlInput1" className="form-label">Full Name</label>
  <input type="text" className="form-control rounded-5 py-2" id="exampleFormControlInput1" />
</div>
<div className="mb-3 col-12 col-md-5">
  <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
  <input type="email" className="form-control rounded-5 pe-4 py-2" id="exampleFormControlInput1" />
</div>
<div className="mb-3 col-12 col-md-5">
  <label htmlFor="exampleFormControlInput1" className="form-label">Phone</label>
  <input type="email" className="form-control rounded-5 pe-4 py-2" id="exampleFormControlInput1" />
</div>
<div className="mb-3 col-12 col-md-5">
  <label htmlFor="exampleFormControlInput1" className="form-label">Company(optional)</label>
  <input type="email" className="form-control rounded-5 pe-4 py-2" id="exampleFormControlInput1" />
</div>
            </div>
<div className="mb-3 px-4 col-12 ">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">Message</label>
  <textarea className="form-control rounded-4" id="exampleFormControlTextarea1" rows="4" placeholder="Briefly tell us about your project and your current goals. How can we help you?"></textarea>
</div>
<button className="btn btn-dark rounded-5 ms-4 mb-5">Send Message</button>
         </div>
      </div>
      </section> */}
 <ContactForm/>
<Footer/>
   </>
}