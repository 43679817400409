import React from "react";
import Slider from "react-slick";

import partner1 from '../../assets/Partners/1 DELL.png'
import partner2 from '../../assets/Partners/2 Schneider-Electric-Logo.jpg'
// import partner3 from '../../assets/Partners/3.png'
import partner5 from '../../assets/Partners/5 fortinet-logo.png'
import partner6 from '../../assets/Partners/7.png'
import partner7 from '../../assets/Partners/9.png'
import partner8 from '../../assets/Partners/10 BOSCH.png'
import partner9 from '../../assets/Partners/11.png'
import partner10 from '../../assets/Partners/12.jpg'
import partner11 from '../../assets/Partners/13 Han.jpg'
import partner12 from '../../assets/Partners/14 mile.png'
import partner13 from '../../assets/Partners/15 lensec.png'
import partner14 from '../../assets/Partners/16.png'
import partner15 from '../../assets/Partners/17.jpg'
import partner16 from '../../assets/Partners/18.png'
import partner17 from '../../assets/Partners/19.png'
import partner18 from '../../assets/Partners/20.png'
import partner19 from '../../assets/Partners/21.png'
import partner20 from '../../assets/Partners/22.png'
import partner21 from '../../assets/Partners/23.png'
import partner22 from '../../assets/Partners/24.png'
import partner23 from '../../assets/Partners/25.png'
import partner24 from '../../assets/Partners/26.png'
import partner25 from '../../assets/Partners/27.png'
import partner26 from '../../assets/Partners/28.png'

function PartnersSlider() {
  const settings = {
   //  dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,

        }
      }
    ]
   };
  return (
   <section className="b-5">
    <div className="slider-container mb-5 ">
      <Slider {...settings} className="">
        <div className="paddingSlider w-100">
<img src={partner1} className="w-100 " alt="" />        
</div>
<div className="paddingSlider">       
    <img src={partner2} className="w-100" alt="" />        
        </div>
        <div className="d-flex justify-content-center">       
       <img src={partner5} className="w-75" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner6} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner7} className="w-100" alt="" />        
        </div>
                <div className="paddingSlider">       
       <img src={partner8} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner9} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner10} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner11} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner12} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner13} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner14} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner15} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner16} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner17} className="w-50" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner18} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner19} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner20} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner21} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner22} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner23} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner24} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner25} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">       
       <img src={partner26} className="w-75" alt="" />        
        </div>
      </Slider>
    </div>
   </section>
  );
}

export default PartnersSlider;
