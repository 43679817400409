import Navbar from "../navbar/navbar";
import partnerStyle from './Partner.module.css'
// import logos from '../../assets/Client Logo (3).png'
import logos2 from '../../assets/partners.png'
import logos3 from '../../assets/partners2.png'
import Footer from "../footer/footer";

export default function Partner (){
   return <>
<Navbar/>
<section >
  <div className={`d-flex justify-content-center align-items-center ${partnerStyle.bgImage}`}>
    <div className="p-5 bg-light-me rounded-5 ms-5 me-5 col-md-6">
    <span className="rounded-5 bg-success text-white py-2 px-3">Partners</span>
      <h1 className={`pt-4 prostoOne fw-bold`}>Collaborating with innovative partners.</h1>
    </div>
  </div>
</section>
<section className='container my-5 text-center'>

   <div className="w-75 m-auto pb-5 mb-5">
      <h2>Our Partners</h2>
      <p>Unique and powerful suite of software to run your entire business, brought to you  transform the way you work.</p>
   </div>
<div className="container m-auto">
   {/* <img src={logos} className="w-100" alt="partners logo" /> */}
   <img src={logos2} className="w-100 mt-5" alt="partners logo" />
   <img src={logos3} className="w-100 mt-5" alt="partners logo" />

</div>
</section>

      <Footer/>

   </>
}