import { Link } from 'react-router-dom'
import footerStyle from './footer.module.css'
import location from '../../assets/Location.png'
import phone from '../../assets/Calling.png'
import message from '../../assets/Message.png'
import time from '../../assets/Time Circle.png'
import fb from '../../assets/main/Vector (13).png'
import linkedin from '../../assets/main/mdi_linkedin.png'
import {useState } from 'react'
import axios from 'axios'

export default function Footer(){
   const [email,setEmail]=useState("");
   const [loading,setLoading]=useState(false) 
   const [alert, setAlert] = useState({ type: '', message: '', visible: false });
   const regex=new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$')
const sendEmail= async()=>{
   
   setLoading(true)
   if (regex.test(email)) {
      try {
       const response = await axios.post(`https://api.nets-is.com/api/Contact/HelpMe/${email}`);
       console.log('successfully:', response.data);
       setAlert({ type: 'success', message: 'Email sent successfully!', visible: true });
       setEmail("");
       
     } catch (error) {
       console.error('Error submitting the form:', error);
       setAlert({ type: 'danger', message: 'Failed to send the email. Please try again later.', visible: true });
     }
     finally{
         setLoading(false)
     }
   }
   else{
      setAlert({ type: 'danger', message: 'Invalid email. Email must be like example@gmail.com .', visible: true });
      setLoading(false)
   }

}
// useEffect(()=>{

// },[email])

   return <>
   <section className={`${footerStyle.footerBg}`}>
      <div className='container pt-5'>
         <div className='row justify-content-center align-items-center text-center border-bottom border-dark pb-1'>
         <div className='col-md-4'>
            <div className='d-flex justify-content-center align-items-center pb-2'>
               <div className='pe-3'>
               <img src={location} className='w-100' alt="" />
               </div>
               <h6>address</h6>
            </div>
   <p className='pb-2'>4 Esmat Mansour, 8th area,
Nasr City, Cairo, Egypt</p>
<div className=''>
<div className='d-flex justify-content-center align-items-center pb-2'>
               <div className='pe-3'>
               <img src={phone} className='w-100' alt="" />
               </div>
               <h6>+20222703426 - 
01029059916</h6>
            </div>
            <div className='d-flex justify-content-center align-items-center pb-2'>
               <div className='pe-3'>
               <img src={time} className='w-100' alt="" />
               </div>
               <p className='fw-semibold'>From Sunday to Thursday: 9:00 AM - 5:00 PM</p>
            </div>
            <div className='d-flex justify-content-center align-items-center pb-2'>
               <div className='pe-3'>
               <img src={message} className='w-100' alt="" />
               </div>
               <h6>info@nets-is.com</h6>
            </div>
</div>
</div>
<div className='col-md-4'>
<ul className="nav flex-column">
  <li className="nav-item">
  <Link className="nav-link text-dark" to="#">Help Center</Link>
  <Link className="nav-link text-dark" to="#">Privacy Policy</Link>
  <Link className="nav-link text-dark" to="#">FAQ</Link>
  <Link className="nav-link text-dark" to="#">Terms & Condition</Link>
  <Link className="nav-link text-dark" to="#">Community</Link>
  </li>
</ul>
</div>
<div className='col-md-4'>
      <p>We look forward to hearing from you and assisting with your IT infrastructure and light current needs.</p>
   <div className="mb-3 d-flex">
   {alert.visible && (
            <div
              className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
              style={{ zIndex: 1050, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            >
              <div className={`alert alert-${alert.type} p-4 `} role="alert">
                
                <p>{alert.message}</p>
                <div className='d-flex justify-content-center'>

                <button
                  className={`btn btn-${alert.type} `}
                  onClick={() => setAlert({ ...alert, visible: false })}
                >
                  OK
                </button>
                </div>
              </div>
            </div>
          )}
  <input type="email" onChange={(e)=>setEmail(e.target.value)} className="form-control rounded-2 pe-4 py-2" id="exampleFormControlInput1" placeholder="Email"/>
  <button className="btn btn-success rounded-2 ms-4" onClick={sendEmail} disabled={loading}>
   {loading?'Sending...':'Send'}
   </button>

</div>
</div>
         </div>
<div className='row my-3  pe-5 '>
   <div className="col-md-10 col-8"></div>
   <div className="col-md-1 col-2 text-end">
   <Link to=''  className='px-2 '>
   <img src={fb} className='' alt="facebook link" />
   </Link>
   </div>
   <div className="col-md-1 col-2 text-start">
   <Link to='https://www.linkedin.com/company/netsiseg/' target="_blank"  className='px-2'>
   <img src={linkedin} className='' alt="linkedin link" />
   </Link>
   </div>
  
</div>
      </div>
      <div className="bg-dark text-center py-3">      
      <div className="text-white">
        <>Copyright © 2024 Designed by 
       <Link className='text-decoration-none text-white' to="https://nasatechnology.net"> NASA technology. </Link>
         All rights reserved.</>
      </div>
      </div>
   </section>
   
   </>
}