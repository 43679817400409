import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import contact8 from '../../assets/Icon (8).png'
import contact9 from '../../assets/Icon (9).png'
import contact10 from '../../assets/Icon (10).png'
import axios from 'axios';
const ContactForm = () => {
   const [loading,setLoading]=useState(false) 
   const [alert, setAlert] = useState({ type: '', message: '', visible: false });
  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      company: '',
      massage: ''
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required('Full Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      phone: Yup.string().required('Phone is required'),
      massage: Yup.string().required('Message is required')
    }),
    onSubmit:async  (values, { resetForm }) => {
        try {
            setLoading(true)
          const response = await axios.post('https://api.nets-is.com/api/Contact', values);
          console.log('successfully:', response.data);
          setAlert({ type: 'success', message: 'Message sent successfully!', visible: true });
          resetForm();
          
        } catch (error) {
          console.error('Error submitting the form:', error);
          setAlert({ type: 'danger', message: 'Failed to send the message. Please try again later.', visible: true });
        }
        finally{
            setLoading(false)
        }
      }
    });

  return (
    <section className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-6 p-5">
          <div className="p-3">
            <h2 className="h1">Contact Us. It’s Easy.</h2>
            <p>Reach out to us today for tailored IT solutions that drive your business forward. Contact us now to discuss your needs!</p>

            <div className="d-flex pt-4">
              <div className="pe-3">
                <img src={contact8} alt="" />
              </div>
              <div>
                <h6 className="text-muted">Call Today</h6>
                <p className="fw-bold">+20222703426 - 01029059916</p>
              </div>
            </div>

            <div className="d-flex pt-4">
              <div className="pe-3">
                <img src={contact9} alt="" />
              </div>
              <div>
                <h6 className="text-muted">Sunday To Thursday</h6>
                <p className="fw-bold">Response hours: 9:00 AM - 5:00 PM</p>
              </div>
            </div>

            <div className="d-flex pt-4">
              <div className="pe-3">
                <img src={contact10} alt="" />
              </div>
              <div>
                <h6 className="text-muted">Cairo Office</h6>
                <p className="fw-bold">4 Esmat Mansour, 8th area, Nasr City, Cairo, Egypt</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 px-5">
        {alert.visible && (
            <div
              className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
              style={{ zIndex: 1050, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            >
              <div className={`alert alert-${alert.type} p-4 `} role="alert">
                
                <p>{alert.message}</p>
                <div className='d-flex justify-content-center'>

                <button
                  className={`btn btn-${alert.type} `}
                  onClick={() => setAlert({ ...alert, visible: false })}
                >
                  OK
                </button>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={formik.handleSubmit}>
            <div className="row justify-content-around">
              <div className="mb-3 col-12 col-md-5">
                <label htmlFor="fullName" className="form-label">Full Name</label>
                <input
                  type="text"
                  className="form-control rounded-5 py-2"
                  id="fullName"
                  {...formik.getFieldProps('fullName')}
                />
                {formik.touched.fullName && formik.errors.fullName ? (
                  <div className="text-danger">{formik.errors.fullName}</div>
                ) : null}
              </div>

              <div className="mb-3 col-12 col-md-5">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control rounded-5 pe-4 py-2"
                  id="email"
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-danger">{formik.errors.email}</div>
                ) : null}
              </div>

              <div className="mb-3 col-12 col-md-5">
                <label htmlFor="phone" className="form-label">Phone</label>
                <input
                  type="text"
                  className="form-control rounded-5 pe-4 py-2"
                  id="phone"
                  {...formik.getFieldProps('phone')}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="text-danger">{formik.errors.phone}</div>
                ) : null}
              </div>

              <div className="mb-3 col-12 col-md-5">
                <label htmlFor="company" className="form-label">Company (optional)</label>
                <input
                  type="text"
                  className="form-control rounded-5 pe-4 py-2"
                  id="company"
                  {...formik.getFieldProps('company')}
                />
              </div>
            </div>

            <div className="mb-3 px-4 col-12">
              <label htmlFor="massage" className="form-label">Message</label>
              <textarea
                className="form-control rounded-4"
                id="massage"
                rows="4"
                placeholder="Briefly tell us about your project and your current goals. How can we help you?"
                {...formik.getFieldProps('massage')}
              ></textarea>
              {formik.touched.massage && formik.errors.massage ? (
                <div className="text-danger">{formik.errors.massage}</div>
              ) : null}
            </div>

            <button type="submit" className="btn btn-dark rounded-5 ms-4 mb-5" disabled={loading} >
             {loading? 'Sending...':'Send Message'}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
