import Navbar from "../navbar/navbar";
import solutionsStyle from './solutions.module.css'
import solution1 from '../../assets/Photo.png'
import solution2 from '../../assets/Photo (3).png'
import solution3 from '../../assets/Photo (2).png'
import results from '../../assets/Image (2).png'
import vector6 from '../../assets/Vector (6).png'
import vector7 from '../../assets/Vector (7).png'
import vector8 from '../../assets/Vector (8).png'
import contact8 from '../../assets/Icon (8).png'
import contact9 from '../../assets/Icon (9).png'
import contact10 from '../../assets/Icon (10).png'
import image4 from '../../assets/5889649-removebg-preview.png'
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import ContactForm from "../contact/contact-form";

export default function Solutions (){
   return <>
<Navbar/>
<section >
  <div className={`d-flex justify-content-center align-items-center ${solutionsStyle.bgImage}`}>
    <div className="p-5 bg-light-me rounded-5 ms-5 me-5 col-md-6">
    <span className="rounded-5 bg-success text-white py-2 px-3">Solutions</span>
      <h1 className={`pt-4 prostoOne fw-bold`}>Delivering transformative solutions.</h1>
    </div>
  </div>
</section>
<section className='container my-5'>
   <div className='d-flex justify-content-center'>
      <div className='col-12  col-lg-6 text-center mb-3'>
      <h2>Our Solutions</h2>
      <p className='text-muted'>Nets Integrated Solutions crafted a comprehensive plan to address these challenges, leveraging our expertise in IT infrastructure and light current solutions. The project was executed in several phases:
      </p>
      </div>
   </div>
   <div className='row justify-content-center align-items-center '>
<div className='col-md-3 col-sm-6 mb-5'>
   <div className='item shadow rounded-5 px-5 pb-5'>
<img src={solution1} className='my-2 w-100'  alt="" />
<h4 className='pb-3'>IT Infrastructure Overhaul</h4>
<p>Assessment and Planning: Conducted a thorough assessment of the existing infrastructure and identified critical .....
</p>
<Link to='/iT-infrastructure' className='btn btn-success rounded-5 px-3 py-2'>See more</Link>
   </div>
</div>
<div className='col-md-3 col-sm-6 mb-5'>
   <div className='item shadow rounded-5 px-5 pb-5'>
<img src={solution2} className='my-4 w-100' alt="" />
<h4 className='pb-3'>Advanced Light Current Solutions</h4>
<p>CCTV and Surveillance Systems: Installed high-definition CCTV cameras with  ..</p>
<Link to='/advanced-light' className='btn btn-success rounded-5 px-3 py-2'>See more</Link>
   </div>
</div>
<div className='col-md-3 col-sm-6 mb-5'>
   <div className='item shadow rounded-5 px-5 pb-5'>
<img src={solution3} className='my-2 w-100' alt="" />
<h4 className='pb-3'>Digital Transformation and Energy Efficiency</h4>
<p>Smart Building Solutions:* Introduced smart building technologies, .....
</p>
<Link to='/digital-transformation' className='btn btn-success rounded-5 px-3 py-2'>See more</Link>
   </div>
</div>
<div className='col-md-3 col-sm-6 mb-5'>
   <div className='item shadow rounded-5 px-5 pb-5 text-center'>
    <div>
<img src={image4} className='my-2 w-75' alt="" />
    </div>
<h4 className='pb-3'>Cybersecurity solutions</h4>
<p>Cybersecurity solutions are designed to protect an organization’s digital assets.....
</p>
<Link to='/cybersecurity' className='btn btn-success rounded-5 px-3 py-2'>See more</Link>
   </div>
</div>
      </div>
</section>
<section className="container py-5 mt-5">
        <div className="row">
        <div className="col-md-6">
            <img src={results} className="w-100" alt="" />
        </div>
          <div className="col-md-6 text-start">
            <div className="px-5">
              <span className="rounded-5 bg-success text-white py-2 px-3">Results</span>
              <div className="d-flex pt-5">
                <div className="">
<img src={vector6} alt="" />
                </div>
                <div className="ps-3">
<h5>Enhanced Operational Efficiency</h5>
<p> The new IT infrastructure reduced downtime by 70%, enabling smoother operations and improving tenant satisfaction.</p>
                </div>
              </div>
              <div className="d-flex pt-4">
                <div className="">
<img src={vector7} alt="" />
                </div>
                <div className="ps-3">
<h5>Improved Security</h5>
<p>The advanced CCTV, access control, and fire alarm systems significantly enhanced the safety and security of the malls and management buildings.</p>                
</div>
              </div>
              <div className="d-flex pt-4">
                <div className="">
<img src={vector8} alt="" />
                </div>
                <div className="ps-3">
<h5>Scalability</h5>
<p>The scalable IT solutions allowed the client to seamlessly integrate new properties and services into the existing infrastructure.</p>
                </div>
              </div>
              <div className="d-flex pt-4">
                <div className="">
<img src={vector8} alt="" />
                </div>
                <div className="ps-3">
<h5>Energy Savings</h5>
<p>Smart building solutions reduced energy consumption by 25%, aligning with the client's sustainability goals and reducing operational costs.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="container pb-5 my-5">
      <div className="p-5 rounded-5 ms-5 me-5  text-center">
    <span className="rounded-5 bg-success text-white py-2 px-3">Pricing</span>
      <h1 className={`pt-4 prostoOne fw-bold`}>Our Pricing Plan</h1>
    </div>
<div className="row">
<div className="col-md-4 pb-5">
<div className="bg-dark p-4 text-white rounded-4">
  <h2>Basic Plan</h2>
  <p className="border-bottom pb-3">The argument in favor of using to 
filler text goes something.
</p>
<div className="d-flex align-items-center">
<h2 className="pe-2">$35</h2>
<span className="rounded-5 bg-dark-subtle text-dark py-2 px-3">Monthly</span>
</div>
</div>
<div className="p-3">
  <div className="d-flex ">
    <div className="pe-3">
<img src={right} className="w-100" alt="" />
    </div>
<p className="">24/7 system monitoring</p>
  </div>
  <div className="d-flex ">
    <div className="pe-3">
<img src={right} className="w-100" alt="" />
    </div>
<p className="">Security management
</p>
  </div>
  <div className="d-flex ">
    <div className="pe-3">
<img src={right} className="w-100" alt="" />
    </div>
<p className="">Secure finance backup
</p>
  </div>
  <div className="d-flex ">
    <div className="pe-3">
<img src={right} className="w-100" alt="" />
    </div>
<p className="">Remote support</p>
  </div>
</div>
<div className="text-center">
<Link to="/contact-us" className="btn btn-secondary rounded-5 px-5 w-100 py-2">Get This Plan</Link>
</div>
</div>
<div className="col-md-4 pb-5">
<div className="bg-dark p-4 text-white rounded-4">
  <h2>Standard Plan</h2>
  <p className="border-bottom pb-3">The argument in favor of using to 
filler text goes something.
</p>
<div className="d-flex align-items-center">
<h2 className="pe-2">$56</h2>
<span className="rounded-5 bg-success text-white py-2 px-3">Monthly</span>
</div>
</div>
<div className="p-3">
  <div className="d-flex ">
    <div className="pe-3">
<img src={right} className="w-100" alt="" />
    </div>
<p className="">24/7 system monitoring</p>
  </div>
  <div className="d-flex ">
    <div className="pe-3">
<img src={right} className="w-100" alt="" />
    </div>
<p className="">Security management
</p>
  </div>
  <div className="d-flex ">
    <div className="pe-3">
<img src={right} className="w-100" alt="" />
    </div>
<p className="">Secure finance backup
</p>
  </div>
  <div className="d-flex ">
    <div className="pe-3">
<img src={right} className="w-100" alt="" />
    </div>
<p className="">Remote support</p>
  </div>
</div>
<div className="text-center">
<Link to="/contact-us" className="btn btn-success rounded-5 px-5 w-100 py-2">Get This Plan</Link>
</div>
</div>
<div className="col-md-4">
<div className="bg-dark p-4 text-white rounded-4">
  <h2>Standard Plan</h2>
  <p className="border-bottom pb-3">The argument in favor of using to 
filler text goes something.
</p>
<div className="d-flex align-items-center">
<h2 className="pe-2">$75</h2>
<span className="rounded-5 bg-dark-subtle text-dark py-2 px-3">Monthly</span>
</div>
</div>
<div className="p-3">
  <div className="d-flex ">
    <div className="pe-3">
<img src={right} className="w-100" alt="" />
    </div>
<p className="">24/7 system monitoring</p>
  </div>
  <div className="d-flex ">
    <div className="pe-3">
<img src={right} className="w-100" alt="" />
    </div>
<p className="">Security management
</p>
  </div>
  <div className="d-flex ">
    <div className="pe-3">
<img src={right} className="w-100" alt="" />
    </div>
<p className="">Secure finance backup
</p>
  </div>
  <div className="d-flex ">
    <div className="pe-3">
<img src={right} className="w-100" alt="" />
    </div>
<p className="">Remote support</p>
  </div>
</div>
<div className="text-center">
<Link to="/contact-us" className="btn btn-secondary rounded-5 px-5 w-100 py-2">Get This Plan</Link>
</div>
</div>
</div>
      </section> */}
      {/* <section className="container">
      <div className="row justify-content-center align-items-center">
         <div className="col-lg-6 p-5">
        <div className="p-3">
<h2 className="h1">Contact Us. It’s Easy.</h2>
<p>Reach out to us today for tailored IT solutions that drive your business forward. Contact us now to discuss your needs!"</p>
<div className="d-flex pt-4">
      <div className=" pe-3">
<img src={contact8} alt="" />
      </div>
<div className="">
<h6 className="text-muted">Call Today</h6>
<p className="fw-bold">+20222703426 - 
01029059916</p>
</div>
    </div>
    <div className="d-flex pt-4">
      <div className=" pe-3">
<img src={contact9} alt="" />
      </div>
<div className="">
<h6 className="text-muted">Sunday To Thursday</h6>
<p className="fw-bold">Response hours: 9:00 AM - 5:00 PM</p>
</div>
    </div>
    <div className="d-flex pt-4">
      <div className=" pe-3">
<img src={contact10} alt="" />
      </div>
<div className="">
<h6 className="text-muted">Cairo Office</h6>
<p className="fw-bold">4 Esmat Mansour, 8th area,
Nasr City, Cairo, Egypt

</p>
</div>
    </div>
        </div>
         </div>
         <div className="col-lg-6 px-5">
            <div className="row justify-content-around">
         <div className="mb-3 col-12 col-md-5 ">
  <label htmlFor="exampleFormControlInput1" className="form-label">Full Name</label>
  <input type="text" className="form-control rounded-5 py-2" id="exampleFormControlInput1" />
</div>
<div className="mb-3 col-12 col-md-5">
  <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
  <input type="email" className="form-control rounded-5 pe-4 py-2" id="exampleFormControlInput1" />
</div>
<div className="mb-3 col-12 col-md-5">
  <label htmlFor="exampleFormControlInput1" className="form-label">Phone</label>
  <input type="email" className="form-control rounded-5 pe-4 py-2" id="exampleFormControlInput1" />
</div>
<div className="mb-3 col-12 col-md-5">
  <label htmlFor="exampleFormControlInput1" className="form-label">Company(optional)</label>
  <input type="email" className="form-control rounded-5 pe-4 py-2" id="exampleFormControlInput1" />
</div>
            </div>
<div className="mb-3 px-4 col-12 ">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">Message</label>
  <textarea className="form-control rounded-4" id="exampleFormControlTextarea1" rows="4" placeholder="Briefly tell us about your project and your current goals. How can we help you?"></textarea>
</div>
<button className="btn btn-dark rounded-5 ms-4 mb-5">Send Message</button>
         </div>
      </div>
      </section> */}
       <ContactForm/>

      <Footer/>

   </>
}