import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "@fortawesome/fontawesome-free/css/all.css";

import {RouterProvider, createBrowserRouter} from 'react-router-dom'
import './App.css';
import Home from './components/home/home';
import Layout from './components/layout/layout';
import Solutions from './components/solutions/solutions';
import AdvancedLight from './components/solutions/AdvancedLight';
import DigitalTransformation from './components/solutions/digitalTransformation';
import ITInfrastructure from './components/solutions/iTInfrastructure';
import Contact from './components/contact/contact';
import About from './components/about/about';
import Customer from './components/customer/customer';
import Partner from './components/partener/partener';
import Cybersecurity from './components/solutions/cybersecurity';


function App() {
const routers = createBrowserRouter([
   {
      path:'', element: <Layout/>, children:[
         {index: true,  element: <Home/>},
         {path: 'solutions',  element: <Solutions/>},
         {path: 'iT-infrastructure',  element: <ITInfrastructure/>},
         {path: 'advanced-light',  element: <AdvancedLight/>},
         {path: 'digital-transformation',  element: <DigitalTransformation/>},
         {path: 'cybersecurity',  element: <Cybersecurity/>},
         {path: 'contact-us',  element: <Contact/>},
         {path: 'about-us',  element: <About/>},
         {path: 'customers',  element: <Customer/>},
         {path: 'Partners',  element: <Partner/>},
      ]
   }
])
return<>
<RouterProvider router={routers}/>
</>
}

export default App;
