import React from "react";
import Slider from "react-slick";

import customer1 from '../../assets/customers/0.jpg'
import customer2 from '../../assets/customers/1.jpg'
import customer3 from '../../assets/customers/2.png'
import customer4 from '../../assets/customers/3.png'
// import customer5 from '../../assets/customers/4.png'
import customer6 from '../../assets/customers/6.jpg'
import customer7 from '../../assets/customers/8.jpeg'
import customer8 from '../../assets/customers/9.png'
import customer9 from '../../assets/customers/10.png'
import customer10 from '../../assets/customers/11 2.png'
import customer11 from '../../assets/customers/11 Adles.jpeg'
import customer12 from '../../assets/customers/12 1.png'
import customer13 from '../../assets/customers/12.jpg'
import customer14 from '../../assets/customers/13.png'
import customer15 from '../../assets/customers/14 abaza.png'
import customer16 from '../../assets/customers/14.jpg'
import customer17 from '../../assets/customers/15.jpg'
import customer18 from '../../assets/customers/16.png'
import customer20 from '../../assets/customers/17.png'
import customer21 from '../../assets/customers/18.jpg'
import customer22 from '../../assets/customers/19.jpg'
import customer23 from '../../assets/customers/20.jpg'
import customer24 from '../../assets/customers/21.png'
import customer25 from '../../assets/customers/22.png'
import customer26 from '../../assets/customers/23.jpg'
import customer27 from '../../assets/customers/24.png'
import customer28 from '../../assets/customers/40 DHL.png'


function CustomersSlider() {
  const settings = {
   //  dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,

        }
      }
    ]
   };
  return (
   <section className="mb-5">
    <div className="slider-container mb-5 ">
      <Slider {...settings} >
        <div className="paddingSlider">
         <img src={customer1} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer2} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer3} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer4} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer6} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer7} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer8} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer9} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer10} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer11} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer12} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer13} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer14} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer15} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer16} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer17} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer18} className="w-100" alt="" />        
        </div>
        <div className="paddingSlider">
         <img src={customer20} className="w-100" alt="" />        
        </div>
        
      </Slider>
    </div>
   </section>
  );
}

export default CustomersSlider;
